import { STORE_RESULTS_GLOSSARY, STORE_CURRENT_PAGE } from './glossaryAction';

const initialState = {
  resultsGlossary: [],
  currentPage: 1
};

export const glossaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_RESULTS_GLOSSARY:
      return { ...state, resultsGlossary: action.resultsGlossary };
    case STORE_CURRENT_PAGE:
      return { ...state, currentPage: action.currentPage };
    default:
      return state;
  }
};
