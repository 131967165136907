import React from 'react';
import { connect } from 'react-redux';
import ga from 'react-ga';
import { Button, DatePicker, Form, Select, Spin, Radio } from 'antd';
import _ from 'lodash';
import { policyCountrySelect as countries } from '../../constants';
import { searchPolicy, resetFilter, updateFilter } from '../../../components/search/action';
import { fetchInsuranceType, fetchProvider, setInsuranceTypes, setProviderGroups } from '../PolicyAction';
import { userCountrySelector, showCountrySelector } from '../../../components/app/appSelectors';
import './sidebarFilterForm.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;
const API_POLICY_LIBRARY = process.env.REACT_APP_POLICY_COMPARISON;
const endpoint = `${API_POLICY_LIBRARY}search/policies`;

class SidebarFilterForm extends React.Component {
  constructor() {
    super();

    this.resetFilter = this.resetFilter.bind(this);
    this.refineSearch = this.refineSearch.bind(this);
    this.onProviderSearch = _.debounce(this.onProviderSearch, 300);
    this.state = {
      providerSearchValue: '',
      providerSearching: false,
      nextUrl: null
    };
  }

  componentDidMount() {
    this.props.fetchInsuranceType(this.props.filters.country || this.props.userCountry);
  }

  fetchProvider = async value => {
    const { providerSearching, nextUrl } = this.state;
    if (providerSearching || typeof nextUrl === 'undefined') {
      return;
    }
    this.setState({ providerSearching: true });
    try {
      const providerData = await this.props.fetchProvider(nextUrl, value);

      const groups = _.chain(providerData.providerList)
        .groupBy('grouping')
        .map((value, key) => ({ grouping: key, providerList: value }))
        .value();
      this.setState(() => ({
        nextUrl: providerData.nextUrl
      }));
      this.props.setProviderGroups(groups);
    } catch (err) {
      this.setState({
        nextUrl: null
      });
      this.props.setProviderGroups([]);
    }
    this.setState({ providerSearching: false });
  };

  onProviderSearch = value => {
    this.props.setProviderGroups([]);
    this.setState(
      {
        providerSearchValue: value,
        nextUrl: ''
      },
      () => {
        if (value.length !== 0) {
          this.fetchProvider(value);
        }
      }
    );
  };

  onPopupScroll = e => {
    const { providerSearching, providerSearchValue } = this.state;
    if (!providerSearching) {
      const element = e.target;
      if (element.scrollHeight - element.scrollTop < element.clientHeight + 10) {
        this.fetchProvider(providerSearchValue);
      }
    }
  };

  handleClearProvider = value => {
    if (value.length === 0) {
      this.props.setProviderGroups([]);
      this.setState({
        nextUrl: '',
        providerSearchValue: value
      });
    }
  };

  resetFilter() {
    const { resetFilter } = this.props;
    resetFilter();
    this.props.fetchInsuranceType(this.props.userCountry);
    this.props.updateFilter({ country: this.props.userCountry });
    this.props.setInsuranceTypes([]);
    this.props.setProviderGroups([]);
  }

  refineSearch() {
    const { defaultFilters, filters, search, query } = this.props;
    const params = { ...defaultFilters, ...filters };
    search(endpoint, query, params);
    ga.event({
      category: 'Policy Library',
      action: 'Filter',
      label: JSON.stringify(params)
    });
  }

  render() {
    const { form, insuranceTypes, providerGroups, insuranceRequest, userCountry, showCountry } = this.props;
    const { providerSearching } = this.state;
    const { getFieldDecorator } = form;
    return (
      <>
        <div className="policy-sidebar-filter-header">
          <h1>Refine search</h1>
          <button className="policy-sidebar-filter-clear" type="button" onClick={this.resetFilter}>
            Reset
          </button>
        </div>
        <Form layout="horizontal" className="policy-sidebar-filter-form">
          <Form.Item>
            {getFieldDecorator('archived')(
              <RadioGroup>
                <Radio value="false">Current</Radio>
                <Radio value="true">Archived</Radio>
                <Radio value="">All</Radio>
              </RadioGroup>
            )}
          </Form.Item>

          <Form.Item colon={false} label="Product Class">
            {getFieldDecorator('insuranceTypes')(
              <Select
                placeholder="Select product classes"
                labelInValue
                size="large"
                mode="multiple"
                notFoundContent={insuranceRequest ? <Spin size="small" /> : 'Not Found'}
                allowClear
                optionFilterProp="children"
                maxTagCount={5}
              >
                {insuranceTypes?.map(insuranceType => (
                  <Option key={+insuranceType.id}>{insuranceType.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item colon={false} label="Insurer/Underwriter">
            {getFieldDecorator('providers')(
              <Select
                mode="multiple"
                labelInValue
                size="large"
                allowClear
                placeholder="Search insurers or underwriters"
                notFoundContent={providerSearching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.onProviderSearch}
                onPopupScroll={this.onPopupScroll}
                maxTagCount={5}
                onChange={this.handleClearProvider}
              >
                {providerGroups?.map(group => {
                  if (group.grouping === 'undefined') {
                    return group.providerList.map(provider => (
                      <Option key={+provider.id}>{provider.legal_name || provider.name}</Option>
                    ));
                  }
                  return <Option key={group.grouping}>{group.grouping}</Option>;
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item colon={false} label="Date Range">
            {getFieldDecorator('dateRange')(<RangePicker format="YYYY-MM-DD" size="large" />)}
          </Form.Item>
          {showCountry && (
            <Form.Item colon={false} label="Country">
              {getFieldDecorator('country', {
                initialValue: userCountry
              })(
                <Select placeholder="Select a country" size="large">
                  {countries.map(country => (
                    <Option key={country.code} value={country.code}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
          <Form.Item>
            <Button className="policy-sidebar-filter-submit" size="large" type="primary" onClick={this.refineSearch}>
              Refine Search
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const WrappedSidebarFilterForm = Form.create({
  name: 'policy_sidebar_filter',
  onFieldsChange(props, changedFields) {
    if (changedFields.country) {
      props.setInsuranceTypes([]);
      props.setProviderGroups([]);
      props.fetchInsuranceType(changedFields.country.value);
      props.updateFilter({ insuranceTypes: [], providers: [] });
    }
    const isNumeric = str => {
      if (typeof str !== 'string') return false;
      return (
        // eslint-disable-next-line no-restricted-globals
        !isNaN(str) && !isNaN(parseFloat(str))
      );
    };
    const providersValue = changedFields.providers?.value;

    if (providersValue) {
      const newProviders = [];

      providersValue.forEach(provider => {
        if (isNumeric(provider.key)) {
          newProviders.push(provider);
        } else {
          props.providerGroups.forEach(group => {
            if (group.grouping === provider.key) {
              group.providerList.forEach(provider => {
                newProviders.push({ key: provider.id.toString(), label: provider.legal_name || provider.name });
              });
            }
          });
        }
      });
      props.updateFilter({
        ..._.mapValues({ ...changedFields, providers: { ...changedFields.providers, value: newProviders } }, 'value')
      });
    } else {
      props.updateFilter({
        ..._.mapValues({ ...changedFields }, 'value')
      });
    }
  },
  mapPropsToFields({ filters, providerGroups }) {
    let newProviders = [];
    if (filters.providers) {
      filters.providers.forEach(filterProvider => {
        let isGroup = false;
        providerGroups.forEach(group => {
          if (group.grouping !== 'undefined') {
            group.providerList.forEach(groupProvider => {
              if (filterProvider.key === groupProvider.id.toString()) {
                isGroup = true;
                newProviders.push({ key: group.grouping, label: group.grouping });
              }
            });
          }
        });
        if (!isGroup) {
          newProviders.push(filterProvider);
        }
      });
    }
    newProviders = _.uniqBy(newProviders, 'key');
    return {
      country: Form.createFormField({
        value: filters.country
      }),
      dateRange: Form.createFormField({
        value: filters.dateRange
      }),
      insuranceTypes: Form.createFormField({
        value: filters.insuranceTypes
      }),
      providers: Form.createFormField({
        value: newProviders
      }),
      archived: Form.createFormField({
        value: filters.archived
      })
    };
  }
})(SidebarFilterForm);

const mapStateToProps = state => ({
  userCountry: userCountrySelector(state),
  showCountry: showCountrySelector(state),
  ...state.search,
  ...state.PolicyReducer
});

const mapDispatchToProps = {
  search: searchPolicy,
  resetFilter,
  updateFilter,
  fetchProvider,
  fetchInsuranceType,
  setInsuranceTypes,
  setProviderGroups
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSidebarFilterForm);
