import React from 'react';
import ga from 'react-ga';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Button, Menu, Dropdown, Icon } from 'antd';
import Modal from '../modal/Modal';
import Login from '../login/Login';
import { logout } from '../login/loginAction';
import { usernameSelector } from '../app/appSelectors';
import { loginRequestSelector, isLoginSelector } from '../login/loginSelectors';
import './header.scss';
import logo from './images/small-Logo.svg';
import smallLogo from './images/small-logo.gif';

const menu = (
  <Menu>
    <Menu.Item key="services">
      <a href="https://lmigroup.io/eservices/" target="_blank" rel="noopener noreferrer">
        SERVICES
      </a>
    </Menu.Item>
    <Menu.Item key="about-us">
      <a href="https://lmigroup.io/contact-us/" target="_blank" rel="noopener noreferrer">
        CONTACT US
      </a>
    </Menu.Item>
  </Menu>
);

const Header = ({ username, isLogin, loginRequest, logout }) => {
  const handleLogout = () => {
    logout();
    ga.event({
      category: 'User',
      action: 'Logout',
      label: username
    });
    ga.set({ userId: undefined });
  };

  const UserDropdown = (
    <Menu>
      <Menu.Item onClick={handleLogout}>
        <Icon type="logout" />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Header className="header">
      <Dropdown className="hamburger-menu" overlay={menu} trigger={['click']}>
        <i className="fas fa-bars" />
      </Dropdown>
      <div className="header-logo-container">
        <Link to="/" className="header-logo">
          <img src={logo} alt="lmi-support-service-logo" />
        </Link>
      </div>
      <Menu mode="horizontal" className="header-nav">
        <Menu.Item key="home">
          <Link to="/">HOME</Link>
        </Menu.Item>
        <Menu.Item key="services">
          <a href="https://lmigroup.io/eservices/" target="_blank" rel="noopener noreferrer">
            SERVICES
          </a>
        </Menu.Item>
        <Menu.Item key="about-us">
          <a href="https://lmigroup.io/contact-us/" target="_blank" rel="noopener noreferrer">
            CONTACT US
          </a>
        </Menu.Item>
        <Menu.Item key="lmi-home" style={{ paddingBottom: '3px' }}>
          <a href="https://www.lmigroup.io/" target="_blank" rel="noopener noreferrer">
            <img src={smallLogo} alt="LMI Group logo" />
          </a>
        </Menu.Item>
      </Menu>
      {isLogin ? (
        <Dropdown overlay={UserDropdown} trigger={['click']} placement="bottomCenter">
          <div className="header-username">
            <span>{username}</span>
            <Icon type="more" />
          </div>
        </Dropdown>
      ) : (
        <Modal
          toggle={show => (
            <Button className="header-login" type="primary" size="large" onClick={show} loading={loginRequest}>
              {!loginRequest && <i className="fas fa-sign-in-alt" />}
              LOGIN
            </Button>
          )}
        >
          {hide => <Login close={hide} />}
        </Modal>
      )}
    </Layout.Header>
  );
};

const mapStateToProps = state => ({
  username: usernameSelector(state),
  isLogin: isLoginSelector(state),
  loginRequest: loginRequestSelector(state)
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
