import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ga from 'react-ga';
import _ from 'lodash';
import 'core-js/stable';

import store from './store';
import Routes from './routes';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import './index.scss';

if (_.endsWith(window.location.hostname, 'lmisupportservices.com')) {
  ga.initialize('UA-3571806-19', {
    titleCase: false
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App>
      <Routes />
    </App>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
