import { SET_INSURANCE_TYPES, SET_PROVIDER_GROUPS, SEARCH_INSURANCE_REQUEST } from './PolicyAction';

const initialState = {
  insuranceTypes: [],
  providerGroups: [],
  insuranceRequest: false
};

export const PolicyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INSURANCE_TYPES:
      return { ...state, insuranceTypes: action.insuranceTypes };
    case SET_PROVIDER_GROUPS:
      return { ...state, providerGroups: action.providerGroups };
    case SEARCH_INSURANCE_REQUEST:
      return { ...state, insuranceRequest: action.insuranceRequest };
    default:
      return state;
  }
};
