import React, { Component } from 'react';
import { Tag } from 'antd';
import comparisonAvailableLogo from '../assets/comparison-available.png';
import './PolicyCard.scss';

export default class PolicyCard extends Component {
  render() {
    const { data } = this.props;
    const {
      name,
      insurer = { name: '' },
      underwriter = { name: '' },
      wording_url,
      end_date,
      code,
      has_comparison,
      classes = []
    } = data;

    return (
      <div className="policy-card">
        <div className="title-container">
          <h1>
            <a className="policy-name" href={wording_url} target="_blank" rel="noopener noreferrer">
              {name} - {code}
            </a>
          </h1>
          {end_date && <div>Archived</div>}
        </div>
        <div className="insurance-types">
          {classes.map(item => (
            <Tag key={item.id}>{item.name}</Tag>
          ))}
        </div>
        <div className="policy-company-container">
          <div className="policy-company-list">
            <div className="policy-company">
              <i className="far fa-building" />
              {insurer.grouping || insurer.name}
            </div>
            <div className="policy-company">
              <i className="fas fa-pencil-alt" />
              {underwriter.grouping || underwriter.name}
            </div>
          </div>
          {has_comparison && (
            <div className="comparison-available">
              <img src={comparisonAvailableLogo} alt={`Comparison avalible for ${name}`} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
