import React from 'react';
import { connect } from 'react-redux';
import { Affix, Layout } from 'antd';
import SidebarFilterForm from '../components/SidebarFilterForm';
import ResultList from '../../../components/search/ResultList';
import PolicyCard from '../components/PolicyCard';
import { searchPolicy, flipPolicy } from '../../../components/search/action';
import { userCountrySelector } from '../../../components/app/appSelectors';
import './PolicySearchResult.scss';

const { Sider, Content } = Layout;
const API_POLICY_LIBRARY = process.env.REACT_APP_POLICY_COMPARISON;

const SearchResult = ({ results, flip, insuranceTypeOptions, fetchInsuranceType, searching, userCountry }) => {
  return (
    <Layout className="policy-sr-container">
      <Sider width={320} style={{ background: '#fff' }}>
        <Affix offsetTop={32}>
          <aside className="sr-sider">
            <SidebarFilterForm
              defaultFilters={{
                country: userCountry
              }}
              insuranceTypeOptions={insuranceTypeOptions}
              fetchInsuranceType={fetchInsuranceType}
              searching={searching}
            />
          </aside>
        </Affix>
      </Sider>
      <Content>
        <ResultList
          component={PolicyCard}
          results={results}
          endpoint={`${API_POLICY_LIBRARY}search/policies`}
          flip={flip}
        />
      </Content>
    </Layout>
  );
};

const mapStateToProps = state => ({
  userCountry: userCountrySelector(state),
  ...state.search
});

const mapDispatchToProps = dispatch => ({
  search: searchPolicy,
  flip: (endpoint, page, pageSize) => dispatch(flipPolicy(endpoint, page, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
