/* eslint-disable dot-notation */
import React from 'react';
import { Table, Spin } from 'antd';
import { groupBy } from 'lodash';

import './financialResultsList.scss';

const Title = ({ country }) => (
  <>
    {country && (
      <div className="filter-country">
        Filtered by Country: <span>{country}</span>
      </div>
    )}
  </>
);

export default class FinancialResultsList extends React.PureComponent {
  render() {
    const columnsA = [
      {
        title: 'General Insurers',
        dataIndex: 'name',
        key: 'name',
        width: '40%'
      },
      {
        title: 'S&P Rating',
        dataIndex: 'rating',
        key: 'rating',
        width: '25%'
      },
      {
        title: 'AM Best Rating',
        dataIndex: 'amBestSymbol',
        key: 'amBestSymbol',
        width: '25%'
      }
    ];

    const columnsB = [
      {
        title: 'Reinsurers',
        dataIndex: 'name',
        key: 'name',
        width: '40%'
      },
      {
        title: 'S&P Rating',
        dataIndex: 'rating',
        key: 'rating',
        width: '25%'
      },
      {
        title: 'AM Best Rating',
        dataIndex: 'amBestSymbol',
        key: 'amBestSymbol',
        width: '25%'
      }
    ];

    const { ratingsRequest, ratingList, country } = this.props;
    const groupedRatings = groupBy(ratingList, term => term.type);
    return (
      <div>
        {ratingsRequest ? (
          <div className="fr-results-spinning-container">
            <Spin tip="Loading ratings..." size="large" />
          </div>
        ) : (
          groupedRatings['General Insurer'] && (
            <div className="fr-result-list-container">
              <Table
                columns={columnsA}
                dataSource={groupedRatings['General Insurer']}
                rowKey={item => item.id}
                pagination={false}
                title={() => <Title country={country} />}
              />
            </div>
          )
        )}
        {groupedRatings['Reinsurer'] && (
          <div className="fr-result-list-container">
            <Table
              columns={columnsB}
              dataSource={groupedRatings['Reinsurer']}
              rowKey={item => item.id}
              pagination={false}
              title={() => <Title country={!groupedRatings['General Insurer'] && country} />}
            />
          </div>
        )}
      </div>
    );
  }
}
