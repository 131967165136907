import React from 'react';
import { connect } from 'react-redux';
import { Affix, Layout } from 'antd';
import SidebarFilterForm from '../components/SidebarFilterForm';
import ResultList from '../../../components/search/ResultList';
import { searchCase, flipCase } from '../../../components/search/action';
import LawCard from '../components/LawCard';
import arcBanner from '../assets/benchmark-banner-arc.jpg';
import './searchResult.scss';

const { Sider, Content } = Layout;

const SearchResult = ({ results, flip }) => (
  <>
    <div className="sr-banner">
      <img
        src={arcBanner}
        alt="Case summaries provided by A R Conolly & Company Lawyers in their daily newsletter Banchmark"
      />
    </div>
    <Layout className="sr-container">
      <Sider width={320} style={{ background: '#fff' }}>
        <Affix offsetTop={32}>
          <aside className="sr-sider">
            <SidebarFilterForm defaultFilters={{ country: 'AU' }} />
          </aside>
        </Affix>
      </Sider>
      <Content>
        <ResultList
          component={LawCard}
          results={results}
          endpoint={`${process.env.REACT_APP_API_BASE}case_law/cases`}
          flip={flip}
        />
      </Content>
    </Layout>
  </>
);

const mapStateToProps = ({ search }) => ({ ...search });

const mapDispatchToProps = dispatch => ({
  search: searchCase,
  flip: (endpoint, page, pageSize) => dispatch(flipCase(endpoint, page, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
