import React from 'react';
import './SmallCard.scss';

const SmallCard = ({ card }) => {
  const { img, title } = card;
  return (
    <div className="small-card">
      <div className="small-card-left">
        <img src={img} alt={title} />
      </div>
      <div className="small-card-right">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default SmallCard;
