import React from 'react';
import { Row, Col } from 'antd';

import benchmarkLogo from '../assets/logo-benchmark.svg';
import arcLogo from '../assets/logo-arc.svg';

import './portal.scss';

export default class Portal extends React.PureComponent {
  render() {
    return (
      <div className="cl-portal-container">
        <div className="page-title">
          <em>Case</em>Law
        </div>
        <p className="page-intro">This facility enables you to search our entire library or view the latest cases.</p>
        <main>
          <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 32, xxl: 32 }} type="flex" justify="center">
            <Col xs={{ span: 24, order: 2 }} sm={20} md={18} xl={{ span: 6, order: 1 }} xxl={5}>
              <div className="cl-portal-benchmark">
                <h2>
                  Case Summaries provided by A R Conolly &amp; Company Lawyers in their daily newsletter Benchmark.
                </h2>
                <img src={benchmarkLogo} alt="benchmark logo" />
                <img src={arcLogo} alt="arc logo" />
              </div>
            </Col>
            <Col xs={{ span: 24, order: 1 }} sm={20} md={18} xl={{ span: 12, order: 2 }} xxl={10}>
              <div className="cl-portal-brief">
                <div className="cl-portal-brief-section">
                  <h2>All the latest cases</h2>
                  <p>
                    As new technologies emerge and work practices evolve, new situations arise and responsibilities
                    shift, with these changes affecting how policies are interpreted and respond. Court cases illustrate
                    these changing conditions, and provide valuable information as to how and why a determination was
                    made. Cases occur every day, with the outcomes underpinning many current trends while throwing
                    others to the wind. To keep abreast of these changes and for interesting reading, visit our Case Law
                    facility regularly to search the library or view the latest cases.
                  </p>
                </div>
                <div className="cl-portal-brief-section" />
              </div>
            </Col>
          </Row>
        </main>
      </div>
    );
  }
}
