import axios from 'axios';
import qs from 'qs';
import * as HttpStatus from 'http-status-codes';
import { initialize } from '../app/appAction';

const API_LMI_ADMIN = process.env.REACT_APP_API_LMI_ADMIN;
const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';
const MSG_LOGIN_FAIL = 'The username or password is incorrect.';

export const types = {
  USER_LOGIN: 'login/USER_LOGIN',
  USER_LOGOUT: 'login/USER_LOGOUT',
  LOGIN_REQUEST: 'login/LOGIN_REQUEST',
  ERROR_MESSAGE: 'login/ERROR_MESSAGE'
};

export const userLogin = () => ({ type: types.USER_LOGIN });

export const loginRequest = loginRequest => ({ type: types.LOGIN_REQUEST, loginRequest });

export const setErrorMessage = errorMessage => ({ type: types.ERROR_MESSAGE, errorMessage });

export const login = values => {
  const { username, password, rememberMe } = values;
  const data = { username, password, grant_type: 'password', client_id: 14 };
  return async dispatch => {
    dispatch(loginRequest(true));
    dispatch(setErrorMessage(''));
    const url = `${API_LMI_ADMIN}token`;
    try {
      const res = await axios.post(url, qs.stringify(data));
      const token = res.data.access_token;
      const date = new Date().getTime();
      const expiryDays = rememberMe ? 7 : 1;
      const expiry = date + 60 * 60 * 24 * expiryDays * 1000;
      localStorage.setItem('token', token);
      localStorage.setItem('expiry', expiry);
      dispatch(initialize());
      return Promise.resolve(res);
    } catch ({ response }) {
      const status = response ? response.status : null;
      switch (status) {
        case HttpStatus.BAD_REQUEST:
          dispatch(setErrorMessage(MSG_LOGIN_FAIL));
          return Promise.reject(MSG_LOGIN_FAIL);
        default:
          dispatch(setErrorMessage(MSG_ERROR));
          return Promise.reject(MSG_ERROR);
      }
    } finally {
      dispatch(loginRequest(false));
    }
  };
};

export const logout = () => dispatch => {
  dispatch({ type: types.USER_LOGOUT });
  localStorage.removeItem('token');
  localStorage.removeItem('linkgen_token');
  localStorage.removeItem('expiry');
  // if (window.location.pathname !== '/') {
  window.location.replace('/');
  // }
};
