import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ga from 'react-ga';
import { Link, withRouter } from 'react-router-dom';
import { Col, Button } from 'antd';
import Modal from '../../../modal/Modal';
import Login from '../../../login/Login';
import { isLoginSelector } from '../../../login/loginSelectors';
import './MenuSection.scss';

const COLOR_SELECTED_MENU = '#F1C400';

const MenuSection = ({ menuItem, location, isLogin }) => {
  const openMedia = () => {
    ga.outboundLink(
      {
        label: 'https://www.lmigroup.io/news/'
      },
      () => {
        /* eslint-disable no-console */
        console.info('ga: outbound to https://www.lmigroup.io/news/');
      }
    );
    window.open('https://www.lmigroup.io/news/');
  };

  const currentLocation = '/'.concat(location.pathname.split('/')[1]);
  const { sectionIcon, sectionTitle, link, openNew } = menuItem;

  const section = (
    <>
      <img src={sectionIcon} alt={sectionTitle} />
      <div className="menu-section-title" style={{ color: currentLocation === link && COLOR_SELECTED_MENU }}>
        {sectionTitle}
      </div>
    </>
  );

  return (
    <Col span={4} className="menu-section">
      {isLogin || menuItem.public ? (
        <>
          {openNew ? (
            <Button role="menuitem" type="link" tabIndex="0" onClick={openMedia} onKeyDown={e => e.preventDefault()}>
              {section}
            </Button>
          ) : (
            <Link to={link}>{section}</Link>
          )}
        </>
      ) : (
        <Modal
          toggle={show => (
            <Button role="menuitem" type="link" tabIndex="0" onClick={show} onKeyDown={e => e.preventDefault()}>
              {section}
            </Button>
          )}
        >
          {hide => <Login close={hide} />}
        </Modal>
      )}
    </Col>
  );
};

const mapStateToProps = state => ({ isLogin: isLoginSelector(state) });

export default compose(withRouter, connect(mapStateToProps))(MenuSection);
