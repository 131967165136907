import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ga from 'react-ga';
import Layout from '../../components/layout/Layout';
import SubNav from '../../components/subNav/SubNav';
import Search from '../../components/search/Search';
import { searchPolicy, resetFilter, updateFilter, resetSearch } from '../../components/search/action';
import SearchFilterForm from './components/SearchFilterForm';
import SearchResults from './scenes/SearchResults';
import Portal from './scenes/Portal';
import { fetchInsuranceType } from './PolicyAction';
import { userCountrySelector } from '../../components/app/appSelectors';
import { searchSelector } from '../../components/search/selectors';
import './index.scss';

const API_POLICY_LIBRARY = process.env.REACT_APP_POLICY_COMPARISON;

const PolicyLibrary = ({
  location,
  loading,
  results,
  userCountry,
  fetchInsuranceType,
  search,
  resetFilter,
  updateFilter,
  resetSearch
}) => {
  useEffect(() => {
    ga.pageview(location.pathname);
    fetchInsuranceType(userCountry);
    updateFilter({ country: userCountry });
  }, [location, userCountry, fetchInsuranceType, updateFilter]);

  return (
    <Layout>
      <SubNav />
      <div className="pl-search-container">
        <Search
          placeholder="Search for policies"
          url={`${API_POLICY_LIBRARY}search/policies`}
          defaultFilters={{ country: userCountry }}
          category="Policy Library"
          search={search}
          resetFilter={resetFilter}
          updateFilter={updateFilter}
          resetSearch={resetSearch}
        >
          <SearchFilterForm />
        </Search>
      </div>
      {results === null && !loading ? <Portal /> : <SearchResults />}
    </Layout>
  );
};

const mapStateToProps = state => ({ userCountry: userCountrySelector(state), ...searchSelector(state) });

const mapDispatchToProps = {
  fetchInsuranceType,
  search: searchPolicy,
  resetFilter,
  updateFilter,
  resetSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyLibrary);
