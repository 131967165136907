import React from 'react';
import { connect } from 'react-redux';
import ga from 'react-ga';
import { Button, DatePicker, Form, Select } from 'antd';
import _ from 'lodash';
import IndustrySelect from './IndustrySelect';
import { searchCase, resetFilter, updateFilter } from '../../../components/search/action';
import { lawCountrySelect as countries } from '../../constants';
import './sidebarFilterForm.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const endpoint = `${process.env.REACT_APP_API_BASE}case_law/cases`;

const SidebarFilterForm = ({ form, defaultFilters, filters, search, query, resetFilter }) => {
  const refineSearch = () => {
    const params = { ...defaultFilters, ...filters };
    search(endpoint, query, params);
    ga.event({
      category: 'Case Law',
      action: 'Filter',
      label: JSON.stringify(params)
    });
  };

  const { getFieldDecorator } = form;

  return (
    <>
      <div className="sidebar-filter-header">
        <h1>Refine search</h1>
        <Button type="link" onClick={resetFilter}>
          Reset
        </Button>
      </div>
      <Form layout="horizontal" colon={false} className="sidebar-filter-form">
        <Form.Item label="Date Range">
          {getFieldDecorator('dateRange')(<RangePicker format="YYYY-MM-DD" size="large" />)}
        </Form.Item>
        <Form.Item label="Country">
          {getFieldDecorator('country')(
            <Select placeholder="Select a country" size="large">
              {countries.map(country => (
                <Option key={country.code} value={country.code}>
                  {country.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Industry">{getFieldDecorator('industry')(<IndustrySelect size="large" />)}</Form.Item>
        <Form.Item>
          <Button className="sidebar-filter-submit" size="large" type="primary" onClick={refineSearch}>
            Refine Search
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const WrappedSidebarFilterForm = Form.create({
  name: 'case_law_sidebar_filter',
  onFieldsChange(props, changedFields) {
    props.updateFilter({
      ..._.mapValues(changedFields, 'value')
    });
  },
  mapPropsToFields({ filters }) {
    return {
      country: Form.createFormField({
        value: filters.country
      }),
      dateRange: Form.createFormField({
        value: filters.dateRange
      }),
      industry: Form.createFormField({
        value: filters.industry
      })
    };
  }
})(SidebarFilterForm);

const mapStateToProps = ({ search }) => ({ ...search });

const mapDispatchToProps = { search: searchCase, resetFilter, updateFilter };

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSidebarFilterForm);
