import React, { Component } from 'react';
import { Row, Col } from 'antd';
import insurancePolicyIcon from '../../assets/home-second-dollar-umbrella.png';
import claimFormsIcon from '../../assets/home-second-claims.png';
import lawCasesIcon from '../../assets/home-second-law-cases.png';
import './fourCards.scss';

const fourCardsData = [
  {
    key: '1',
    src: insurancePolicyIcon,
    title: 'Insurance Policies',
    count: '21,465',
  },
  { key: '3', src: claimFormsIcon, title: 'Glossary', count: '950' },
  { key: '4', src: lawCasesIcon, title: 'Law Cases', count: '9,795' },
];

const SmallCountCard = ({ src, title, count }) => (
  <Col xs={22} sm={20} md={14} lg={6} xl={6} xxl={4} className="col-container">
    <div className="card-container">
      <div className="card-container-left">
        <img src={src} alt={title} />
      </div>
      <div className="card-container-right">
        <h2 className="count">{`${count}+`}</h2>
        <p>{title}</p>
      </div>
    </div>
  </Col>
);

export default class FourCards extends Component {
  render() {
    return (
      <Row type="flex" align="middle" justify="center" gutter={{ xs: 0, sm: 16, md: 16, lg: 16, xl: 24, xxl: 32 }}>
        {fourCardsData.map(card => (
          <SmallCountCard src={card.src} title={card.title} key={card.key} count={card.count} />
        ))}
      </Row>
    );
  }
}
