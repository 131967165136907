import {
  FINANCIAL_MAIN_COMPONENT_SHOW,
  FINANCIAL_SEARCH_CONTENT,
  SET_ALL_RATINGS,
  RATINGS_LAW_REQUEST
} from './financialAction';

const initialState = {
  showMainFinancial: true,
  financialSearchContent: { country: '' },
  allRatings: {},
  ratingsRequest: false
};

export const financialReducer = (state = initialState, action) => {
  switch (action.type) {
    case FINANCIAL_MAIN_COMPONENT_SHOW:
      return { ...state, showMainFinancial: action.showMainFinancial };
    case FINANCIAL_SEARCH_CONTENT:
      return { ...state, financialSearchContent: action.financialSearchContent };
    case SET_ALL_RATINGS:
      return {
        ...state,
        allRatings: action.allRatings
      };
    case RATINGS_LAW_REQUEST:
      return {
        ...state,
        ratingsRequest: action.ratingsRequest
      };
    default:
      return state;
  }
};
