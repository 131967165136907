import axios from 'axios';
import { parseLinkHeader } from '../../components/app/appAction';
import { userCountrySelector } from '../../components/app/appSelectors';

const API_POLICY_LIBRARY = process.env.REACT_APP_POLICY_COMPARISON;
const APP_API_KEY = process.env.REACT_APP_POLICY_COMPARISON_API_KEY;
export const SET_INSURANCE_TYPES = 'policy/SET_INSURANCE_TYPES';
export const SET_PROVIDER_GROUPS = 'policy/SET_PROVIDER_GROUPS';
export const SEARCH_INSURANCE_REQUEST = 'policy/SEARCH_INSURANCE_REQUEST';

export const setInsuranceTypes = insuranceTypes => ({
  type: SET_INSURANCE_TYPES,
  insuranceTypes
});

export const setProviderGroups = providerGroups => ({
  type: SET_PROVIDER_GROUPS,
  providerGroups
});

export const searchInsuranceRequest = insuranceRequest => ({
  type: SEARCH_INSURANCE_REQUEST,
  insuranceRequest
});

export const fetchInsuranceType = country => async dispatch => {
  dispatch(searchInsuranceRequest(true));
  const url = `${API_POLICY_LIBRARY}classes`;
  const params = { country };
  try {
    const res = await axios({
      method: 'get',
      url,
      headers: {
        Accept: 'application/json; v=1.0',
        'X-Api-Key': APP_API_KEY
      },
      params
    });
    dispatch(setInsuranceTypes(res.data));
  } catch (err) {
    console.error(err);
  }
  dispatch(searchInsuranceRequest(false));
};

export const fetchProvider = (nextUrl, value) => async (dispatch, getState) => {
  const filters = getState().search.filters;
  const userCountry = userCountrySelector(getState());
  const filterCountry = filters.country || userCountry;
  const url = nextUrl || `${API_POLICY_LIBRARY}companies?country=${filterCountry}&page=1&pageSize=10&q=${value}`;

  try {
    const res = await axios({
      method: 'get',
      url,
      headers: {
        Accept: 'application/json; v=1.0',
        'X-Api-Key': APP_API_KEY
      }
    });

    const {
      data,
      headers: { link }
    } = res;
    const parsedLink = link && parseLinkHeader(link);
    const providerData = {
      // providerList: data.data,
      providerList: data.items,
      nextUrl: parsedLink && parsedLink.next
    };
    return Promise.resolve(providerData);
  } catch (err) {
    return Promise.reject(err);
  }
};
