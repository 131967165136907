import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Select } from 'antd';
import data from '../data/anzsic_2006.min.json';

const { Option } = Select;

const industryList = _.chain(data.data)
  .flatMap('children')
  .flatMap('children')
  .flatMap('children')
  .value();

const IndustrySelect = ({ size, placeholder, value, onChange }) => {
  const onSelect = value => {
    if (onChange) {
      onChange(value);
    }
  };

  const options = industryList.map(item => <Option key={item.code}>{item.title}</Option>);

  return (
    <Select
      showSearch
      size={size}
      value={value}
      placeholder={placeholder}
      showArrow={false}
      notFoundContent={null}
      onSelect={onSelect}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {options}
    </Select>
  );
};

IndustrySelect.propTypes = {
  size: PropTypes.oneOf(['large', 'default']),
  placeholder: PropTypes.string
};

IndustrySelect.defaultProps = {
  size: 'default',
  placeholder: 'Search Industry'
};

export default IndustrySelect;
