import React from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Select, Row, Col, Spin } from 'antd';
import _ from 'lodash';
import { fetchInsuranceType, fetchProvider, setInsuranceTypes, setProviderGroups } from '../PolicyAction';
import { updateFilter } from '../../../components/search/action';
import { userCountrySelector, showCountrySelector } from '../../../components/app/appSelectors';
import { policyCountrySelect as countries } from '../../constants';
import './SearchFilterForm.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.onProviderSearch = _.debounce(this.onProviderSearch, 300);
    this.state = {
      providerSearchValue: '',
      providerSearching: false,
      nextUrl: null
    };
  }

  componentDidMount() {
    this.props.fetchInsuranceType(this.props.filters.country || this.props.userCountry);
  }

  fetchProvider = async value => {
    const { providerSearching, nextUrl } = this.state;
    if (providerSearching || typeof nextUrl === 'undefined') {
      return;
    }
    this.setState({ providerSearching: true });
    try {
      const providerData = await this.props.fetchProvider(nextUrl, value);

      const groups = _.chain(providerData.providerList)
        .groupBy('grouping')
        .map((value, key) => {
          if (!key) {
            return { grouping: 'undefined', providerList: value };
          }
          return { grouping: key, providerList: value };
        })
        .value();

      this.setState(() => ({
        nextUrl: providerData.nextUrl
      }));
      this.props.setProviderGroups(groups);
    } catch (err) {
      this.setState({
        nextUrl: null
      });
      this.props.setProviderGroups([]);
    }
    this.setState({ providerSearching: false });
  };

  onProviderSearch = value => {
    this.props.setProviderGroups([]);
    this.setState(
      {
        providerSearchValue: value,
        nextUrl: ''
      },
      () => {
        if (value.length !== 0) {
          this.fetchProvider(value);
        }
      }
    );
  };

  onPopupScroll = e => {
    const { providerSearching, providerSearchValue } = this.state;
    if (!providerSearching) {
      const element = e.target;
      if (element.scrollHeight - element.scrollTop < element.clientHeight + 10) {
        this.fetchProvider(providerSearchValue);
      }
    }
  };

  handleClearProvider = value => {
    if (value.length === 0) {
      this.props.setProviderGroups([]);
      this.setState({
        nextUrl: '',
        providerSearchValue: value
      });
    }
  };

  render() {
    const { form, insuranceTypes, providerGroups, insuranceRequest, userCountry, showCountry } = this.props;
    const { providerSearching } = this.state;
    const { getFieldDecorator } = form;
    return (
      <Form layout="horizontal" className="policy-search-filter-form">
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item colon={false} label="Product Class">
              {getFieldDecorator('insuranceTypes')(
                <Select
                  placeholder="Select product classes"
                  labelInValue
                  size="large"
                  mode="multiple"
                  notFoundContent={insuranceRequest ? <Spin size="small" /> : 'Not Found'}
                  filterOption
                  allowClear
                  optionFilterProp="children"
                  maxTagCount={3}
                >
                  {insuranceTypes?.map(insuranceType => (
                    <Option key={+insuranceType.id}>{insuranceType.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item colon={false} label="Insurer/Underwriter">
              {getFieldDecorator('providers')(
                <Select
                  mode="multiple"
                  labelInValue
                  size="large"
                  allowClear
                  placeholder="Search insurers or underwriters"
                  notFoundContent={providerSearching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.onProviderSearch}
                  onPopupScroll={this.onPopupScroll}
                  onChange={this.handleClearProvider}
                  maxTagCount={3}
                >
                  {providerGroups?.map(group => {
                    if (group.grouping === 'undefined') {
                      return group.providerList.map(provider => (
                        <Option key={+provider.id}>{provider.legal_name || provider.name}</Option>
                      ));
                    }
                    return <Option key={group.grouping}>{group.grouping}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item colon={false} label="Date Range">
              {getFieldDecorator('dateRange')(<RangePicker format="YYYY-MM-DD" size="large" />)}
            </Form.Item>
          </Col>
          {showCountry && (
            <Col span={12}>
              <Form.Item colon={false} label="Country">
                {getFieldDecorator('country', {
                  initialValue: userCountry
                })(
                  <Select placeholder="Select a country" size="large" optionFilterProp="children">
                    {countries.map(country => (
                      <Option key={country.code} value={country.code}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

const WrappedSearchFilterForm = Form.create({
  name: 'policy_library_search_filter',
  onFieldsChange(props, changedFields) {
    if (changedFields.country) {
      props.setInsuranceTypes([]);
      props.setProviderGroups([]);
      props.fetchInsuranceType(changedFields.country.value);
      props.updateFilter({ insuranceTypes: [], providers: [] });
    }
    const isNumeric = str => {
      if (typeof str !== 'string') return false;
      return (
        // eslint-disable-next-line no-restricted-globals
        !isNaN(str) && !isNaN(parseFloat(str))
      );
    };
    const providersValue = changedFields.providers?.value;

    if (providersValue) {
      const newProviders = [];

      providersValue.forEach(provider => {
        if (isNumeric(provider.key)) {
          newProviders.push(provider);
        } else {
          props.providerGroups.forEach(group => {
            if (group.grouping === provider.key) {
              group.providerList.forEach(provider => {
                newProviders.push({ key: provider.id.toString(), label: provider.legal_name || provider.name });
              });
            }
          });
        }
      });
      props.onChange({
        ..._.mapValues({ ...changedFields, providers: { ...changedFields.providers, value: newProviders } }, 'value')
      });
    } else {
      props.onChange({
        ..._.mapValues({ ...changedFields }, 'value')
      });
    }
  },
  mapPropsToFields({ filters, providerGroups }) {
    let newProviders = [];
    if (filters.providers) {
      filters.providers.forEach(filterProvider => {
        let isGroup = false;
        providerGroups.forEach(group => {
          if (group.grouping !== 'undefined') {
            group.providerList.forEach(groupProvider => {
              if (filterProvider.key === groupProvider.id.toString()) {
                isGroup = true;
                newProviders.push({ key: group.grouping, label: group.grouping });
              }
            });
          }
        });
        if (!isGroup) {
          newProviders.push(filterProvider);
        }
      });
    }
    newProviders = _.uniqBy(newProviders, 'key');
    return {
      country: Form.createFormField({
        value: filters.country
      }),
      dateRange: Form.createFormField({
        value: filters.dateRange
      }),
      insuranceTypes: Form.createFormField({
        value: filters.insuranceTypes
      }),
      providers: Form.createFormField({
        value: newProviders
      })
    };
  }
})(SearchFilterForm);

const mapStateToProps = state => ({
  userCountry: userCountrySelector(state),
  showCountry: showCountrySelector(state),
  ...state.PolicyReducer
});

const mapDispatchToProps = dispatch => ({
  updateFilter: filter => dispatch(updateFilter(filter)),
  fetchProvider: (nextUrl, value) => dispatch(fetchProvider(nextUrl, value)),
  fetchInsuranceType: country => dispatch(fetchInsuranceType(country)),
  setInsuranceTypes: data => dispatch(setInsuranceTypes(data)),
  setProviderGroups: data => dispatch(setProviderGroups(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSearchFilterForm);
