import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Pagination, Spin } from 'antd';
import * as numeral from 'numeral';
import './resultList.scss';

const ResultList = ({ loading, results, filters, component, flip, endpoint }) => {
  const onPageChanged = (page, pageSize) => {
    flip(endpoint, page, pageSize);
  };

  const Component = component;

  return (
    <div className="result-list-container">
      {!loading && results ? (
        <>
          <div className="result-list-header">
            <span className="result-list-count">
              <em>{numeral(results.total_count).format('0,0')}</em>&nbsp;results
            </span>
          </div>
          <div className="result-list-results">
            <ul>{results && results.items && results.items.map(i => <Component key={i.id || i.code} data={i} />)}</ul>
          </div>
          <div className="result-list-pager">
            <Pagination
              defaultCurrent={1}
              current={filters.page}
              hideOnSinglePage
              pageSize={30}
              total={results.total_count}
              onChange={onPageChanged}
            />
          </div>
        </>
      ) : (
        <div className="result-list-spinner">
          <Spin tip="Loading..." size="large" />
        </div>
      )}
    </div>
  );
};

ResultList.defaultProps = {
  results: null
};

ResultList.propTypes = {
  component: PropTypes.func.isRequired,
  results: PropTypes.object
};

const mapStateToProps = ({ search }) => ({ ...search });

export default connect(mapStateToProps, null)(ResultList);
