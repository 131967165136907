import { createSelector } from 'reselect';

export const initializedSelector = state => state.app.initialized;

export const userSelector = state => state.app.user;

export const usernameSelector = createSelector(userSelector, user => (user ? user.userName : ''));

export const userCountrySelector = createSelector(userSelector, user => (user ? user.country.code : ''));

export const showCountrySelector = createSelector(userSelector, user => user && user.roles.includes('PCI Admin'));
