import { combineReducers } from 'redux';
import appReducer from './components/app/appReducer';
import loginReducer from './components/login/loginReducer';
import { searchReducer } from './components/search/reducer';
import { PolicyReducer } from './scenes/policyLibrary/PolicyReducer';
import { financialReducer } from './scenes/financialRatings/financialReducer';
import { glossaryReducer } from './scenes/insuranceGlossary/glossaryReducer';

export default combineReducers({
  app: appReducer,
  login: loginReducer,
  search: searchReducer,
  PolicyReducer,
  financialReducer,
  glossaryReducer
});
