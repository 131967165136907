import axios from 'axios';
import { message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import { userLogin, logout } from '../login/loginAction';

const APP_API_BASE = process.env.REACT_APP_API_BASE;
const MSG_LOGIN_RESTRICT = 'Sorry, your account is UNAUTHORIZED.';
// const MSG_ACCOUNT_EXPIRED = 'Your session has expired. Please log in again.';
// const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';

export const types = {
  APP_INITIALIZING: 'app/APP_INITIALIZING',
  APP_INITIALIZED: 'app/APP_INITIALIZED',
  REQUEST_FEEDBACK: 'app/REQUEST_FEEDBACK',
  REQUEST_USER: 'app/REQUEST_USER',
  RECEIVE_USER: 'app/RECEIVE_USER'
};

export const setRequestFeedback = requestFeedback => ({ type: types.REQUEST_FEEDBACK, requestFeedback });

const requestUser = () => ({ type: types.REQUEST_USER });
const receiveUser = data => ({ type: types.RECEIVE_USER, user: data });

export const fetchUser = () => async dispatch => {
  dispatch(requestUser());
  const url = `${APP_API_BASE}user`;
  try {
    const res = await axios.get(url);
    dispatch(receiveUser(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveUser());
    return Promise.reject(err);
  }
};

const getLinkgenToken = () => {
  const parsedUrl = new URL(window.location);
  const key = parsedUrl.searchParams.get('key');
  const encryptedData = parsedUrl.searchParams.get('EncryptedData');
  return key && encryptedData ? btoa(`key=${key}&EncryptedData=${encodeURIComponent(encryptedData)}`) : null;
};

export const initialize = () => async dispatch => {
  dispatch({ type: types.APP_INITIALIZING });

  axios.interceptors.response.use(
    res => res,
    async err => {
      const status = err && err.response ? err.response.status : null;
      switch (status) {
        case HttpStatus.UNAUTHORIZED:
          message.destroy();
          message.error(MSG_LOGIN_RESTRICT);
          dispatch(logout());
          return Promise.reject(err);
        default:
          return Promise.reject(err);
      }
    }
  );

  const expiry = localStorage.getItem('expiry') ? localStorage.getItem('expiry') : null;

  if (expiry && expiry < new Date().getTime()) {
    await dispatch(logout());
  }

  let token = getLinkgenToken() || localStorage.getItem('linkgen_token');

  if (token) {
    localStorage.setItem('token', token);
    localStorage.setItem('linkgen_token', token);
    localStorage.setItem('expiry', new Date().getTime() + 60 * 60 * 24 * 1 * 1000);
    axios.defaults.headers.common.Authorization = `LinkGen ${token}`;
  } else {
    token = localStorage.getItem('token');
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  if (token) {
    await dispatch(userLogin());
    await dispatch(fetchUser());
  }
  dispatch({ type: types.APP_INITIALIZED });
};

export const parseLinkHeader = header => {
  if (header.length === 0) {
    throw new Error('input must not be of zero length');
  }
  const parts = header.split('",');
  const links = {};
  parts.forEach(p => {
    const section = p.split(';');
    if (section.length !== 2) {
      throw new Error("section could not be split on ';'");
    }

    const url = section[0].replace(/<(.*)>/, '$1').trim();
    let name = section[1].replace(/rel="(.*)"/, '$1').trim();
    if (name.includes('=')) {
      name = name.split('"')[1];
    }
    links[name] = url;
  });
  return links;
};

export const getTotalPageNumber = last => {
  if (last.length === 0) {
    return null;
  }
  const splitUrl = last.split('&');
  const params = {};
  splitUrl.forEach((value, index) => {
    if (index === 0) {
      return null;
    }
    const section = value.split('=');
    if (section.length !== 2) {
      throw new Error("section could not be split on ';'");
    }
    const itemName = section[0];
    const itemValue = section[1];
    params[itemName] = itemValue;
    return null;
  });
  return params;
};
