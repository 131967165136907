import React, { Component } from 'react';
import { Carousel } from 'antd';
import leftArrow from '../assets/left.svg';
import rightArrow from '../assets/right.svg';
import './ratingScale.scss';

const rateStandardData = [
  {
    key: '1',
    rate: 'AAA',
    explanation: `An insurer rated 'AAA' has EXTREMELY STRONG
      financial security characteristics. ‘AAA’ is the highest Insurer Financial Strength Rating assigned by Standard & Poor’s.`
  },

  {
    key: '2',
    rate: 'AA',
    explanation: `An insurer rated ‘AA’ has VERY STRONG financial
      security characteristics, differing only slightly from those rated higher.`
  },
  {
    key: '3',
    rate: 'A',
    explanation: `An insurer rated ‘A’ has STRONG financial security characteristics, but is somewhat more likely to be
      affected by adverse business conditions than are insurers with higher ratings.`
  },
  {
    key: '4',
    rate: 'BBB',
    explanation: `An insurer rated ‘BBB’ has GOOD financial security characteristics, but is more likely to be affected by
      adverse business conditions than are higher rated insurers.`
  },
  {
    key: '5',
    rate: 'BB',
    explanation: `An insurer rated ‘BB’ has MARGINAL financial
      security characteristics. Positive attributes exist, but
      adverse business conditions could lead to insufficient ability to meet financial commitments.`
  },
  {
    key: '6',
    rate: 'B',
    explanation: `An insurer rated ‘B’ has WEAK financial security
      characteristics. Adverse business conditions will likely impair its ability to meet financial commitments.`
  },
  {
    key: '7',
    rate: 'CCC',
    explanation: `An insurer rated ‘CCC’ has VERY WEAK financial
      security characteristics, and is dependent on favorable business conditions to meet financial commitments.`
  },
  {
    key: '8',
    rate: 'CC',
    explanation: `An insurer rated ‘CC’ has EXTREMELY WEAK financial security characteristics and is likely not to meet some of its financial commitments.`
  },
  {
    key: '9',
    rate: 'R',
    explanation: `An insurer rated ‘R’ has experienced a REGULATORY ACTION regarding solvency. The rating does not apply
      to insurers subject only to nonfinancial actions such
      as market conduct violations.`
  },
  {
    key: '10',
    rate: 'NR',
    explanation: `An insurer designated ‘NR’ is NOT RATED, which implies no opinion about the insurer’s financial security.`
  }
];

const RatingScaleContainer = ({ rate, explanation }) => (
  <div className="rating-scale-container">
    <div className="rate">{rate}</div>
    <div className="explanation">{explanation}</div>
  </div>
);

export default class CarouselComponent extends Component {
  constructor() {
    super();
    this.rate = React.createRef();
  }

  render() {
    return (
      <div className="carousel-container">
        <button type="button" onClick={() => this.rate.current.prev()}>
          <img src={leftArrow} alt="previous" />
        </button>
        <Carousel ref={this.rate} dots>
          {rateStandardData.map(level => (
            <RatingScaleContainer rate={level.rate} key={level.rate} explanation={level.explanation} />
          ))}
        </Carousel>
        <button type="button" onClick={() => this.rate.current.next()}>
          <img src={rightArrow} alt="next" />
        </button>
      </div>
    );
  }
}
