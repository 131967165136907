import { useEffect } from 'react';
import { connect } from 'react-redux';
import { initialize } from './appAction';
import { initializedSelector } from './appSelectors';
import './app.scss';

const App = ({ children, initialized, initialize }) => {
  useEffect(() => {
    initialize();
  }, [initialize]);

  return initialized ? children : null;
};

const mapStateToProps = state => ({
  initialized: initializedSelector(state)
});

const mapDispatchToProps = { initialize };

export default connect(mapStateToProps, mapDispatchToProps)(App);
