import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './scenes/home/Home';
import PolicyLibrary from './scenes/policyLibrary';
import CaseLaw from './scenes/caseLaw';
import FinancialRatings from './scenes/financialRatings';
import InsuranceGlossary from './scenes/insuranceGlossary';

const PrivateRoute = ({ component: Component, render, ...rest }) => (
  <Route
    {...rest}
    render={
      render ||
      (props =>
        localStorage.getItem('token') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ))
    }
  />
);

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <PrivateRoute exact path="/financial-ratings" component={FinancialRatings} />
      <PrivateRoute exact path="/policy-library" component={PolicyLibrary} />
      <PrivateRoute exact path="/case-law" component={CaseLaw} />
      <Route exact path="/insurance-glossary" component={InsuranceGlossary} />
    </Switch>
  </Router>
);
