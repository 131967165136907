import React from 'react';
import { connect } from 'react-redux';
import ga from 'react-ga';
import { Form, Button, Input, Icon, Checkbox, Alert } from 'antd';
import { login } from './loginAction';
import { usernameSelector } from '../app/appSelectors';
import { loginRequestSelector, errorMessageSelector } from './loginSelectors';
import logo from '../header/images/small-Logo.svg';
import './login.scss';

const FormItem = Form.Item;

const Login = ({ form, username, loginRequest, errorMessage, login, close }) => {
  const { getFieldDecorator } = form;

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        login(values).then(() => {
          ga.event({
            category: 'User',
            action: 'Login',
            label: form.getFieldValue('username')
          });
          ga.set({ userId: username });
          close();
        });
      }
    });
  };

  return (
    <>
      <div className="login-modal-logo-container">
        <img src={logo} alt="logo" />
      </div>
      {errorMessage !== '' && <Alert message={errorMessage} type="error" />}
      <Form onSubmit={onSubmit} className="login-form">
        <FormItem>
          {getFieldDecorator('username', {
            validateTrigger: 'onBlur',
            rules: [{ required: true, message: 'Please input your username.' }]
          })(
            <Input
              prefix={<Icon type="user" />}
              placeholder="Username"
              disabled={loginRequest}
              size="large"
              spellCheck={false}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your password.' }]
          })(
            <Input.Password prefix={<Icon type="lock" />} placeholder="Password" size="large" disabled={loginRequest} />
          )}
        </FormItem>
        <FormItem className="login-remember-me">
          {getFieldDecorator('rememberMe', {
            valuePropName: 'checked',
            initialValue: false
          })(<Checkbox>Remember me</Checkbox>)}
        </FormItem>
        <FormItem>
          <Button className="login-form-btn" type="primary" htmlType="submit" loading={loginRequest} size="large">
            {loginRequest ? 'Logging in' : 'Log in'}
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

const mapStateToProps = state => ({
  username: usernameSelector(state),
  loginRequest: loginRequestSelector(state),
  errorMessage: errorMessageSelector(state)
});

const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Login));
