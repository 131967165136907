import React from 'react';
import { Layout, Button } from 'antd';
import './footer.scss';

const { Footer } = Layout;

export default () => (
  <Footer className="footer">
    <div className="footer-copyright">
      &copy; 1999 - {new Date().getFullYear()} <strong>LMI Group</strong>. All Rights Reserved.
    </div>
    <ul className="footer-social-links">
      <li>
        <Button shape="circle" type="primary" size="small">
          <a href="https://www.facebook.com/LMIGroupPtyLtd/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f" />
          </a>
        </Button>
      </li>
      <li className="site-footer-social-link-item">
        <Button shape="circle" type="primary" size="small">
          <a href="https://www.linkedin.com/company/lmi-group/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in" />
          </a>
        </Button>
      </li>
      <li>
        <Button shape="circle" type="primary" size="small">
          <a href="https://www.youtube.com/user/LMIGROUP" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube" />
          </a>
        </Button>
      </li>
    </ul>
  </Footer>
);
