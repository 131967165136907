import React, { Component } from 'react';
import ga from 'react-ga';
import { Badge, Row, Col, Button, Carousel, Icon } from 'antd';
import Layout from '../../components/layout/Layout';
import FourCards from './components/card/FourCards';
import NewsContainer from '../../components/newsContainer/NewsContainer';
import MenuBar from '../../components/subNav/components/MenuBar';

import logoPolicy from './assets/small-logo-policy.svg';
import logoRisk from './assets/small-logo-risk.svg';
import logoClaims from './assets/small-logo-claims.svg';

import './home.scss';

// RSS
const lmiRssParser = async () => {
  const PROXY = 'https://api.allorigins.win/get?url=';
  const RSS_URL = 'https://lmigroup.io/feed/';

  const parser = new window.DOMParser();
  const res = await fetch(`${PROXY}${RSS_URL}`);
  const { contents } = await res.json();
  const feed = parser.parseFromString(contents, 'text/xml');
  const items = Array.from(feed.querySelectorAll('item'));

  const findSnippetText = str => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || '';
  };

  const findThumbnail = str => {
    const imgSrcRe = /src\s*=\s*"(.+?)"/;
    return imgSrcRe.exec(str)[1] || '';
  };

  const feedItems = items.map(el => ({
    guid: el.querySelector('guid')?.textContent,
    title: el.querySelector('title')?.textContent,
    thumbnail: findThumbnail(el.querySelector('description')?.textContent),
    contentSnippet: findSnippetText(el.querySelector('description')?.textContent),
    creator: el.querySelector('dc\\:creator')?.textContent,
    publishedDate: Date.parse(el.querySelector('pubDate')?.textContent),
    url: el.querySelector('link')?.textContent
  }));

  return feedItems;
};

export default class Home extends Component {
  constructor() {
    super();
    this.slider = React.createRef();
    this.sliderLatestNews = React.createRef();
    this.state = {
      // componentMounted: false,
      innerWidth: window.innerWidth,
      newsList: []
    };
  }

  componentDidMount() {
    // this.setState({ componentMounted: true });
    this.loadLatestNews();
    ga.pageview(this.props.location.pathname);
  }

  loadLatestNews = async () => {
    const feedItems = await lmiRssParser();
    if (feedItems) {
      this.setState({
        newsList: feedItems
      });
    }
  };

  onResize = () =>
    this.setState({
      innerWidth: window.innerWidth
    });

  render() {
    window.onresize = this.onResize;
    const { innerWidth, newsList } = this.state;

    const countNode = (
      <div className="badge-container">
        <p className="count-text">LMI</p>
      </div>
    );
    return (
      <Layout>
        <div className="home-banner-container">
          <div className="banner-text-container">
            <Badge count={countNode}>
              <h1 className="support-services">
                <em>Support</em>Services
              </h1>
            </Badge>
            <div className="banner-text-second">
              Our knowledge sources to provide you with a simple, searchable resource in the one handy location!
            </div>
          </div>
          <div className="menu-bar-container">
            <MenuBar />
          </div>
        </div>

        <main className="home-content-container">
          <div className="welcome-section">
            <div className="page-title">
              <em>Welcome to</em>
              LMI SupportServices
            </div>
            <p className="text">
              Our support services facility brings together many of our knowledge sources to provide you with a simple,
              searchable resource in the one handy location!
            </p>
            <p className="text">
              For your convenience, these knowledge sources have been categorised to provide for faster searching with a
              more suitable and concise result. To utilise the search facility in each category, please enter one or
              more search criteria in the field(s) provided on each page, and then click the ‘Search’ button to view the
              results.
            </p>
            <p className="text">
              We hope to bring you more knowledge sources as the data and information come to hand. If you have any
              suggestions on the type of content you would like to see please feel free to reach out to us.
            </p>
          </div>
          <div className="notice-section">
            <div>
              <h2>Content is only available to subscribers of LMI eServices.</h2>
              <p>
                If you have not already logged in, we recommend that you log into the portal before you start searching.
              </p>
            </div>
            <Button href="https://lmigroup.io/contact-us/" target="_blank" rel="noopener noreferrer">
              CONTACT LMI
            </Button>
          </div>
          <div className="function-section">
            <FourCards />
            <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 16, xl: 48, xxl: 64 }} type="flex" justify="center">
              <Col
                className="card-in-column"
                xs={{ span: 22, order: 1 }}
                sm={20}
                md={14}
                lg={7}
                xl={{ span: 6, order: 2 }}
                xxl={5}
              >
                <div className="card">
                  <div className="card-image fsr" />
                  <div className="card-body">
                    <h2>Financial Strength Ratings</h2>
                    <p>
                      These ratings are the current opinion of the financial security characteristics of an insurance
                      organisation with respect to its ability to pay under its insurance policies and contracts in
                      accordance with their terms.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                className="card-in-column"
                xs={{ span: 22, order: 1 }}
                sm={20}
                md={14}
                lg={7}
                xl={{ span: 6, order: 2 }}
                xxl={5}
              >
                <div className="card">
                  <div className="card-body" style={{ height: '50%' }}>
                    <h2>Research &amp; Education</h2>
                    <ul>
                      <li>
                        <Icon type="right" />
                        Insurance Policies
                      </li>
                      <li>
                        <Icon type="right" />
                        Claims Forms
                      </li>
                      <li>
                        <Icon type="right" />
                        Quotations Slips
                      </li>
                    </ul>
                  </div>
                  <div className="card-image rne" style={{ height: '50%' }} />
                </div>
              </Col>
              <Col
                className="card-in-column"
                xs={{ span: 22, order: 1 }}
                sm={20}
                md={14}
                lg={7}
                xl={{ span: 6, order: 2 }}
                xxl={5}
              >
                <div className="card">
                  <div className="card-image csl" />
                  <div className="card-body">
                    <h2>Case Law</h2>
                    <p>
                      Law Cases occur every day, with the outcomes underpinning many current trends while throwing
                      others to the wind. To keep abreast of these changes and for interesting reading, visit our Case
                      Law facility regularly to view the latest cases.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="function-section eservice-section">
            <div className="page-title">
              <em>Explore</em>
              LMI eServices
            </div>
            <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 16, xl: 48, xxl: 64 }} type="flex" justify="center">
              <Col
                className="card-in-column"
                xs={{ span: 22, order: 1 }}
                sm={20}
                md={14}
                lg={7}
                xl={{ span: 6, order: 2 }}
                xxl={5}
              >
                <Button
                  className="brand-card"
                  href="https://www.lmiriskcoach.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logoRisk} alt="LMI RiskCoach logo" />
                  <h2>
                    <em>LMIRisk</em>Coach
                  </h2>
                </Button>
                <Button
                  className="brand-card"
                  href="https://www.lmipolicycoach.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logoPolicy} alt="LMI PolicyCoach logo" />
                  <h2>
                    <em>LMIPolicy</em>Coach
                  </h2>
                </Button>
                <Button
                  className="brand-card"
                  href="https://www.claimscomparison.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logoClaims} alt="LMI ClaimsComparison logo" />
                  <h2>
                    <em>LMIClaims</em>Comparison
                  </h2>
                </Button>
              </Col>
              <Col
                className="card-in-column"
                xs={{ span: 22, order: 1 }}
                sm={20}
                md={14}
                lg={7}
                xl={{ span: 6, order: 2 }}
                xxl={5}
              >
                <div className="card">
                  <div className="card-image lmicc" />
                  <div className="card-body">
                    <a href="https://continuitycoach.com/" target="_blank" rel="noopener noreferrer">
                      <h2>
                        <em>LMIContinuity</em>Coach
                      </h2>
                    </a>
                    <p>AN ESSENTIAL PLANNING TOOL FOR SURVIVAL AND SUCCESS</p>
                  </div>
                  <div className="card-new">
                    <span>NEW</span>
                  </div>
                </div>
              </Col>
              <Col
                className="card-in-column"
                xs={{ span: 22, order: 1 }}
                sm={20}
                md={14}
                lg={7}
                xl={{ span: 6, order: 2 }}
                xxl={5}
              >
                <div className="card">
                  <div className="card-body">
                    <h2>Calculators &amp; Tools</h2>
                    <ul>
                      <li>
                        <Icon type="right" />
                        <a target="_blank" href="https://www.bicalculator.com/" rel="noopener noreferrer">
                          <em>BI</em>Calculator
                        </a>
                      </li>
                      <li>
                        <Icon type="right" />
                        <a
                          target="_blank"
                          href="https://www.lmigroup.com/RiskCoachCalculators/under-insurance-calculator"
                          rel="noopener noreferrer"
                        >
                          <em>UI</em>Calculator
                        </a>
                      </li>
                      <li>
                        <Icon type="right" />
                        <a
                          target="_blank"
                          href="https://www.lmigroup.com/RiskCoachCalculators/building-cost-calculator"
                          rel="noopener noreferrer"
                        >
                          <em>Building</em>CostCalculator
                        </a>
                      </li>
                      <li>
                        <Icon type="right" />
                        <a target="_blank" href="https://cms.lmigroup.com/bi-explained" rel="noopener noreferrer">
                          <em>BI</em>Explained
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-image lmibie" />
                </div>
              </Col>
            </Row>
            <div className="btn-container">
              <Button
                type="primary"
                href="https://www.lmigroup.io/services/eservices/"
                size="large"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Button>
            </div>
          </div>
          <div className="news-section">
            <div className="page-title">
              <em>Latest</em>
              News
            </div>
            <div className="news-container">
              <Button type="primary" icon="left" onClick={() => this.slider.current.prev()} className="left-button" />
              <div className="carousel-content-container">
                <Carousel
                  dots={false}
                  ref={this.slider}
                  slidesToShow={innerWidth <= 900 ? 1 : 3}
                  slidesToScroll={innerWidth <= 900 ? 1 : 2}
                >
                  {newsList.map(news => (
                    <NewsContainer
                      key={news.guid}
                      title={news.title}
                      thumbnail={news.thumbnail}
                      contentSnippet={news.contentSnippet}
                      creator={news.creator}
                      publishedDate={news.publishedDate}
                      url={news.url}
                    />
                  ))}
                </Carousel>
              </div>
              <Button type="primary" icon="right" onClick={() => this.slider.current.next()} className="right-button" />
            </div>
            <div className="btn-container">
              <Button
                type="primary"
                href="https://www.lmigroup.io/news/"
                size="large"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Button>
            </div>
          </div>
        </main>
      </Layout>
    );
  }
}
