import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ga from 'react-ga';
import _ from 'lodash';
import Layout from '../../components/layout/Layout';
import SubNav from '../../components/subNav/SubNav';
import WrappedFinancialSearch from './components/FinancialSearch';
import Portal from './scenes/Portal';
import FinancialResultsList from './scenes/FinancialResultsList';
import { showMainFinancial, financialSearchContent, fetchAllRatings } from './financialAction';
import { userCountrySelector } from '../../components/app/appSelectors';
import { financialSelector } from './financialSelectors';
import { financialCountrySelect as countries } from '../constants';
import './index.scss';

const FinancialRatings = props => {
  const { location, allRatings, showMainFinancial, fetchAllRatings, storeShowMainFinancial, userCountry } = props;
  const [country, setCountry] = useState('Australia');

  useEffect(() => {
    const country = _.find(countries, c => {
      return c.code === userCountry;
    });
    if (country) {
      setCountry(country.name);
    }
  }, [userCountry]);

  useEffect(() => {
    fetchAllRatings();
    ga.pageview(location.pathname);
  }, [location.pathname, fetchAllRatings]);

  const clickSearch = () => {
    storeShowMainFinancial(false);
  };

  const handleFormChange = changedValues => {
    if ('country' in changedValues) {
      setCountry(changedValues.country);
    }
  };

  return (
    <Layout>
      <SubNav />
      <WrappedFinancialSearch
        {...props}
        handleFormChange={handleFormChange}
        country={country}
        clickSearch={clickSearch}
      />
      <div className="fr-container">
        {!showMainFinancial ? (
          <FinancialResultsList {...props} ratingList={allRatings[country]} country={country} />
        ) : (
          <Portal />
        )}

        <div className="fr-disclaimer">
          <strong>Note:</strong> The ratings shown here are not prepared by LMI Group, but are provided with the kind
          permission of Standard &amp; Poors, which is a division of The McGraw-Hill Companies Inc. To learn more about
          ratings please&nbsp;
          <a href="https://www.standardandpoors.com/en_US/web/guest/home" target="_blank" rel="noopener noreferrer">
            click here.
          </a>
          <br />
          <br />
          The user is reminded that the views expressed by contributors such as Standard &amp; Poors are not necessarily
          those of LMI Group, it is a condition of use of this site that LMI Group cannot be held responsible for any
          loss occasioned to any person acting or refraining from action as a result of the material included in this
          site or reproduced from it.
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return { ...financialSelector(state), userCountry: userCountrySelector(state) };
};

const mapDispatchToProps = {
  storeFinancialSearchContent: financialSearchContent,
  storeShowMainFinancial: showMainFinancial,
  fetchAllRatings
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialRatings);
