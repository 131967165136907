import React from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Select, Row, Col } from 'antd';
import _ from 'lodash';
import IndustrySelect from './IndustrySelect';
import { lawCountrySelect as countries } from '../../constants';
import { userCountrySelector } from '../../../components/app/appSelectors';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SearchFilterForm = ({ form, userCountry }) => {
  const { getFieldDecorator } = form;
  return (
    <Form layout="horizontal" colon={false} className="search-filter-form">
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label="Date Range">
            {getFieldDecorator('dateRange')(<RangePicker format="YYYY-MM-DD" size="large" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Country">
            {getFieldDecorator('country', {
              initialValue: userCountry
            })(
              <Select placeholder="Select a country" size="large" optionFilterProp="children">
                {countries.map(country => (
                  <Option key={country.code} value={country.code}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Industry">{getFieldDecorator('industry')(<IndustrySelect size="large" />)}</Form.Item>
    </Form>
  );
};

const WrappedSearchFilterForm = Form.create({
  name: 'case_law_search_filter',
  onFieldsChange(props, changedFields) {
    props.onChange({
      ..._.mapValues(changedFields, 'value')
    });
  },
  mapPropsToFields({ filters, userCountry }) {
    return {
      country: Form.createFormField({
        value: filters.country || userCountry
      }),
      dateRange: Form.createFormField({
        value: filters.dateRange
      }),
      industry: Form.createFormField({
        value: filters.industry
      })
    };
  }
})(SearchFilterForm);

const mapStateToProps = state => ({
  userCountry: userCountrySelector(state)
});

export default connect(mapStateToProps, null)(WrappedSearchFilterForm);
