import React from 'react';
import ga from 'react-ga';
import { Form, Input } from 'antd';
import './glossarySearch.scss';

const { Search } = Input;

class GlossarySearch extends React.Component {
  searchContentRef = React.createRef();

  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.collectSearchItems(values);
        ga.event({
          category: 'Glossary',
          action: 'Search',
          label: JSON.stringify(values)
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="glossary-search-container">
        <Form layout="inline">
          <Form.Item colon={false}>
            {getFieldDecorator('glossarySearchContent')(
              <Search
                placeholder="Search for terms"
                className="glossary-search-input"
                style={{ width: '100%' }}
                enterButton
                size="large"
                onSearch={this.onSubmit}
              />
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const WrappedGlossarySearch = Form.create({
  onValuesChange(props, changedValues) {
    props.handleFormChange(changedValues);
  },
  mapPropsToFields(props) {
    const { glossarySearchContent } = props;
    return {
      glossarySearchContent: Form.createFormField({
        value: glossarySearchContent
      })
    };
  }
})(GlossarySearch);

export default WrappedGlossarySearch;
