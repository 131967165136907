import React from 'react';
import { Layout, BackTop } from 'antd';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import './layout.scss';

const { Content } = Layout;

export default ({ children }) => (
  <Layout className="layout">
    <Header />
    <Content>{children}</Content>
    <BackTop className="back-top" />
    <Footer />
  </Layout>
);
