import {
  SEARCH_REQUEST,
  SEARCH_RECEIVED,
  SEARCH_RESET_FILTER,
  SEARCH_UPDATE_QUERY,
  SEARCH_UPDATE_FILTER,
  SEARCH_RESET_SEARCH
} from './actionTypes';

const initialState = {
  query: '',
  filters: {
    page: 1,
    pageSize: 30,
    archived: 'false'
  },
  results: null,
  loading: false
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        query: action.query,
        filters: { ...initialState.filters, ...action.filters }
      };
    case SEARCH_RECEIVED:
      return {
        ...state,
        loading: false,
        results: {
          items: action.data.items || action.data.data,
          total_count: action.data.total_count || action.data.total
        }
      };
    case SEARCH_RESET_FILTER:
      return {
        ...state,
        filters: initialState.filters
      };
    case SEARCH_RESET_SEARCH:
      return initialState;
    case SEARCH_UPDATE_QUERY:
      return {
        ...state,
        query: action.query
      };
    case SEARCH_UPDATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.filters }
      };
    default:
      return state;
  }
};
