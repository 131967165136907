import React from 'react';
import { Row, Col, Button } from 'antd';
import viewIcon from '../assets/viewIcon.svg';
import searchIcon from '../assets/searchIcon.svg';
import downloadIcon from '../assets/downloadIcon.svg';

import './PolicyPortal.scss';
import SmallCard from '../components/card/SmallCard';

const smallCardInfo = [
  {
    img: searchIcon,
    title: 'Search'
  },
  {
    img: viewIcon,
    title: 'View'
  },
  {
    img: downloadIcon,
    title: 'Download'
  }
];

export default class Portal extends React.PureComponent {
  render() {
    return (
      <div className="policy-portal-container">
        <div className="page-title">
          <em>Policy</em>Library
        </div>
        <p className="page-intro">Search our entire library of current and archived policy wordings.</p>
        <main>
          <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 16, xl: 48, xxl: 64 }} type="flex" justify="center">
            <Col
              className="policy-portal-card"
              xs={{ span: 22, order: 1 }}
              sm={20}
              md={14}
              lg={7}
              xl={{ span: 6, order: 2 }}
              xxl={5}
            >
              <div className="policy-left-card">
                <div className="policy-left-card-top">
                  <h1>10’s of Thousands</h1>
                  <h2>of Insurance Policies</h2>
                  <p>Easily search and download policies from our extensive library from Insurers around the world.</p>
                </div>
                <div className="policy-left-card-bottom" />
              </div>
            </Col>
            <Col
              className="policy-portal-card"
              xs={{ span: 22, order: 1 }}
              sm={20}
              md={14}
              lg={7}
              xl={{ span: 6, order: 2 }}
              xxl={5}
            >
              {smallCardInfo.map(card => (
                <SmallCard card={card} key={card.title} />
              ))}
            </Col>
            <Col
              className="policy-portal-card"
              xs={{ span: 22, order: 1 }}
              sm={20}
              md={14}
              lg={7}
              xl={{ span: 6, order: 2 }}
              xxl={5}
            >
              <div className="policy-third-card">
                <div className="policy-third-card-top" />
                <div className="policy-third-card-bottom">
                  <h2>Let us know</h2>
                  <p>
                    As always, we would welcome your participation by notifying us of any content that you would like to
                    see included in the Knowledge Centre, as this may be of benefit to our industry as a whole.
                  </p>
                  <Button type="primary" href="https://lmigroup.io/contact-us/" target="_blank">
                    Click Here
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </main>
      </div>
    );
  }
}
