import Axios from "axios";
import { groupBy } from "lodash";
import { setRequestFeedback } from "../../components/app/appAction";

// action types
export const FINANCIAL_MAIN_COMPONENT_SHOW =
  "financial/FINANCIAL_MAIN_COMPONENT_SHOW";
export const FINANCIAL_SEARCH_CONTENT = "financial/FINANCIAL_SEARCH_CONTENT";
export const SET_ALL_RATINGS = "financial/SET_ALL_RATINGS";
export const RATINGS_LAW_REQUEST = "financial/RATINGS_LAW_REQUEST";

const API_BASE = process.env.REACT_APP_API_BASE;
const MSG_ERROR = "Sorry, an error occurred. Please try again later.";

// actions
export const showMainFinancial = showMainFinancial => ({
  type: FINANCIAL_MAIN_COMPONENT_SHOW,
  showMainFinancial
});

export const financialSearchContent = financialSearchContent => ({
  type: FINANCIAL_SEARCH_CONTENT,
  financialSearchContent
});

export const setAllRatings = allRatings => ({
  type: SET_ALL_RATINGS,
  allRatings
});

export const ratingsRequest = ratingsRequest => ({
  type: RATINGS_LAW_REQUEST,
  ratingsRequest
});

export const fetchAllRatings = () => async dispatch => {
  const url = `${API_BASE}financial_ratings`;
  try {
    dispatch({ type: RATINGS_LAW_REQUEST, ratingsRequest: true });
    const res = await Axios.get(url);
    const groupedRatings = groupBy(res.data, term => term.country.name);
    dispatch({ type: SET_ALL_RATINGS, allRatings: groupedRatings });
    dispatch({ type: RATINGS_LAW_REQUEST, ratingsRequest: false });
  } catch {
    dispatch(setRequestFeedback(MSG_ERROR));
    dispatch({ type: RATINGS_LAW_REQUEST, ratingsRequest: false });
  }
};
