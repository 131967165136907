import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ga from 'react-ga';
import { Table, Pagination, Menu, Spin } from 'antd';
import { groupBy } from 'lodash';
import Layout from '../../components/layout/Layout';
import SubNav from '../../components/subNav/SubNav';
import WrappedGlossarySearch from './components/GlossarySearch';
import { storeTotalGlossary, storeResultsGlossary, handleSearchGlossary, fetchAllGlossary } from './glossaryAction';
import './index.scss';

const pageSize = 30;
const startItemIndex = 0;
const initialPage = 1;
const COLOR_SELECTED_ROW = '#fff8dc';

class InsuranceGlossary extends Component {
  constructor() {
    super();
    this.searchContentRef = React.createRef();
    this.state = {
      glossaryCapital: [],
      start: startItemIndex,
      end: pageSize,
      current: initialPage,
      selectedCapitalGroup: '',
      totalGlossary: [],
      glossarySearchContent: '',
      loadingGlossary: false
    };
  }

  componentDidMount() {
    this.loadData();
    ga.pageview(this.props.location.pathname);
  }

  loadData = async () => {
    if (!localStorage.getItem('totalGlossary')) {
      this.setState({ loadingGlossary: true });
      await this.props.fetchAllGlossary();
      this.setState({ loadingGlossary: false });
    }

    const groupedGlossary = JSON.parse(localStorage.getItem('totalGlossary'));

    this.setState({
      totalGlossary: groupedGlossary
    });

    this.props.storeResultsGlossary(groupedGlossary);
    if (this.props.location.hash) {
      const hashWord = decodeURI(this.props.location.hash.slice(1));
      this.setState({
        glossaryCapital: [],
        selectedCapitalGroup: '',
        current: initialPage,
        start: startItemIndex,
        end: pageSize
      });
      this.props.handleSearchGlossary(hashWord, groupedGlossary);
    }
  };

  handlePageChange = (page, pageSize) => {
    window.scroll(0, 100);
    this.setState({
      start: (page - 1) * pageSize,
      end: page * pageSize,
      current: page
    });
  };

  handleClickCapitalMenu = ({ key }) => {
    this.setState({
      current: initialPage,
      glossaryCapital: [key],
      selectedCapitalGroup: this.props.resultsGlossary[key],
      start: startItemIndex,
      end: pageSize
    });
  };

  collectSearchItems = values => {
    const searchContentValue = values.glossarySearchContent ? values.glossarySearchContent : '';
    this.setState({
      glossaryCapital: [],
      selectedCapitalGroup: '',
      current: initialPage,
      start: startItemIndex,
      end: pageSize
    });
    if (searchContentValue.length === 0) {
      this.props.storeResultsGlossary(this.state.totalGlossary);
    } else {
      this.props.handleSearchGlossary(values.glossarySearchContent, this.state.totalGlossary);
    }
  };

  emptyCapital = () => {
    this.setState({
      glossaryCapital: []
    });
  };

  handleFormChange = changedValues => {
    this.setState(
      {
        ...changedValues
      },
      () => {
        const { glossarySearchContent } = this.state;
        if (glossarySearchContent.length === 0) {
          this.props.storeResultsGlossary(this.state.totalGlossary);
        }
      }
    );
  };

  render() {
    const { location, resultsGlossary, storeResultsGlossary } = this.props;
    const { start, end, current, glossaryCapital, selectedCapitalGroup, totalGlossary, loadingGlossary } = this.state;
    const hashWord = decodeURI(location.hash.slice(1)) || [];

    const showGlossaryList = glossaryCapital.length === 0 ? resultsGlossary : selectedCapitalGroup;
    const glossaryKeys = resultsGlossary ? Object.keys(resultsGlossary) : [];
    const flatGlossaryList = showGlossaryList && Object.values(showGlossaryList).flat();
    const tableDataLength = flatGlossaryList && flatGlossaryList.length;
    const pagedList = flatGlossaryList && flatGlossaryList.slice(start, end);
    const showGlossaryData = groupBy(pagedList, term => term.name.substr(0, 1));
    const columns = [
      {
        title: 'Term',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        render(text, record) {
          return {
            props: {
              id: record.id,
              style: {
                background: record.name === hashWord && COLOR_SELECTED_ROW,
                marginTop: record.name === hashWord && '100px',
                zIndex: record.name === hashWord ? 0 : 100
              }
            },
            children: <div id={record.name}>{record.name}</div>
          };
        }
      },
      {
        title: 'Definition',
        dataIndex: 'definition',
        key: 'definition',
        render(text, record) {
          return {
            props: {
              id: record.id,
              style: {
                background: record.name === hashWord && COLOR_SELECTED_ROW
              }
            },
            children: <div dangerouslySetInnerHTML={{ __html: record.definition }} />
          };
        }
      }
    ];

    const renderTable = () => {
      return tableDataLength === 0 ? (
        <div className="glossary-table-container">
          <Table
            columns={columns}
            dataSource={[]}
            rowKey={record => record.id}
            pagination={false}
            locale={{ emptyText: 'No Items Found !' }}
          />
        </div>
      ) : (
        <div className="glossary-table-container">
          <div className="first-line-container">
            <Menu mode="horizontal" selectedKeys={glossaryCapital}>
              {glossaryKeys.map(letter => (
                <Menu.Item key={letter} onClick={({ key }) => this.handleClickCapitalMenu({ key })}>
                  {letter}
                </Menu.Item>
              ))}
            </Menu>
          </div>
          <div className="real-table-container">
            {Object.entries(showGlossaryData).map(([key, value]) => (
              <Fragment key={key}>
                <h2 id={key}>{key}</h2>
                <Table columns={columns} dataSource={value} rowKey={record => record.name} pagination={false} />
              </Fragment>
            ))}
          </div>
          <div className="pagination-container">
            <Pagination
              current={current}
              defaultCurrent={1}
              total={tableDataLength}
              pageSize={pageSize}
              onChange={this.handlePageChange}
              hideOnSinglePage
            />
          </div>
        </div>
      );
    };

    return (
      <Layout>
        <SubNav />
        <WrappedGlossarySearch
          {...this.state}
          totalGlossary={totalGlossary}
          storeResultsGlossary={storeResultsGlossary}
          collectSearchItems={this.collectSearchItems}
          emptyCapital={this.emptyCapital}
          handleFormChange={this.handleFormChange}
        />
        <div className="glossary-container">
          <div className="page-title">
            <em>Insurance</em>Glossary
          </div>
          <div className="glossary-main-component">
            {loadingGlossary ? (
              <div className="glossary-loading-container">
                <Spin tip="Loading..." size="large" />
              </div>
            ) : (
              renderTable()
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { glossaryReducer } = state;
  return {
    ...glossaryReducer
  };
};

const mapDispatchToProps = dispatch => ({
  storeTotalGlossary: data => dispatch(storeTotalGlossary(data)),
  storeResultsGlossary: data => dispatch(storeResultsGlossary(data)),
  handleSearchGlossary: (searchContent, totalGlossary) => dispatch(handleSearchGlossary(searchContent, totalGlossary)),
  fetchAllGlossary: () => dispatch(fetchAllGlossary())
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceGlossary);
