import axios from 'axios';
import { groupBy } from 'lodash';
import { loginRequest } from '../../components/login/loginAction';

const API_BASE = process.env.REACT_APP_API_BASE;

export const STORE_TOTAL_GLOSSARY = 'glossary/STORE_TOTAL_GLOSSARY';
export const STORE_RESULTS_GLOSSARY = 'glossary/STORE_RESULTS_GLOSSARY';
export const STORE_CURRENT_PAGE = 'glossary/STORE_CURRENT_PAGE';

export const storeTotalGlossary = totalGlossary => ({
  type: STORE_TOTAL_GLOSSARY,
  totalGlossary
});

export const storeCurrentPage = currentPage => ({
  type: STORE_CURRENT_PAGE,
  currentPage
});

export const storeResultsGlossary = resultsGlossary => ({
  type: STORE_RESULTS_GLOSSARY,
  resultsGlossary
});

export const handleSearchGlossary = (searchContent, totalGlossary) => dispatch => {
  const query = searchContent.replace(/\W|_/g, '[$&]');
  const regex = new RegExp(query, 'i');
  const results = {};

  for (const [k, v] of Object.entries(totalGlossary)) {
    const currentResults = [];
    for (const object of v) {
      if (object.name.match(regex)) {
        currentResults.push(object);
      }
    }
    if (currentResults.length > 0) {
      results[k] = currentResults;
    }
  }
  dispatch(storeResultsGlossary(results));
};

export const fetchAllGlossary = () => async dispatch => {
  const url = `${API_BASE}glossary?scope=pci`;
  try {
    const res = await axios.get(url);
    const groupedGlossary = groupBy(res.data, term => term.name.substr(0, 1).toUpperCase());
    if (groupedGlossary) {
      localStorage.setItem('totalGlossary', JSON.stringify(groupedGlossary));
      dispatch(storeResultsGlossary(groupedGlossary));
    }
    dispatch(loginRequest(false));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
