import { types } from './loginAction';

const initialState = {
  isLogin: false,
  loginRequest: false,
  errorMessage: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGIN:
      return { ...state, isLogin: true };
    case types.USER_LOGOUT:
      return { ...state, isLogin: false };
    case types.LOGIN_REQUEST:
      return { ...state, loginRequest: action.loginRequest };
    case types.ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };
    default:
      return state;
  }
};
