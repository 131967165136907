import axios from 'axios';
import _ from 'lodash';
import {
  SEARCH_REQUEST,
  SEARCH_RECEIVED,
  SEARCH_UPDATE_QUERY,
  SEARCH_RESET_FILTER,
  SEARCH_UPDATE_FILTER,
  SEARCH_RESET_SEARCH
} from './actionTypes';

const APP_API_KEY = process.env.REACT_APP_POLICY_COMPARISON_API_KEY;

export const searchRequest = (query, filters) => ({
  type: SEARCH_REQUEST,
  query,
  filters
});

export const searchReceived = ({ data }) => ({
  type: SEARCH_RECEIVED,
  data
});

export const updateQuery = query => dispatch => {
  dispatch({
    type: SEARCH_UPDATE_QUERY,
    query
  });
};

// policy library actions
const processPolicyParams = (query, filters) => {
  const args = new URLSearchParams();
  if (query) {
    args.append('q', query);
  }
  if (Object.keys(filters).indexOf('country') >= 0) {
    args.append('country', filters.country);
  }
  if (Object.keys(filters).indexOf('archived') >= 0) {
    args.append('archived', filters.archived);
    if (filters.archived === 'false' && Object.keys(filters).indexOf('dateRange') < 0) {
      args.append('date', new Date().toISOString());
    }
  }
  if (Object.keys(filters).indexOf('dateRange') >= 0) {
    args.append('start', new Date(filters.dateRange[0]).toISOString());
    args.append('end', new Date(filters.dateRange[1]).toISOString());
  }
  if (Object.keys(filters).indexOf('insuranceTypes') >= 0) {
    for (const insuranceType of filters.insuranceTypes) {
      args.append('classes', insuranceType.key);
    }
  }
  if (Object.keys(filters).indexOf('providers') >= 0) {
    for (const provider of filters.providers) {
      args.append('company_ids', provider.key);
    }
  }
  if (Object.keys(filters).indexOf('page') >= 0) {
    args.append('page', filters.page);
  }
  if (Object.keys(filters).indexOf('pageSize') >= 0) {
    args.append('pageSize', filters.pageSize);
  }

  return args;
};

export const searchPolicy = (url, query, filters) => async dispatch => {
  if (url && url.length) {
    const augmented = { ...filters, page: 1, pageSize: 30 };
    dispatch(searchRequest(query, augmented));
    try {
      const params = processPolicyParams(query, augmented);
      const resp = await axios({
        method: 'get',
        url,
        headers: {
          Accept: 'application/json; v=1.0',
          'X-Api-Key': APP_API_KEY
        },
        params
      });
      dispatch(searchReceived(resp));
    } catch (err) {
      // TODO: send global error message
      // console.error(err);
    }
  }
};

export const flipPolicy = (url, page, pageSize) => async (dispatch, getState) => {
  const { search } = getState();
  const { query } = search;
  const filters = { ...search.filters, page, pageSize };
  if (url && url.length) {
    dispatch(searchRequest(query, filters));
    try {
      const params = processPolicyParams(query, filters);
      const resp = await axios({
        method: 'get',
        url,
        headers: {
          Accept: 'application/json; v=1.0',
          'X-Api-Key': APP_API_KEY
        },
        params
      });
      dispatch(searchReceived(resp));
    } catch (err) {
      // TODO: send global error message
      // console.error(err);
    }
  }
};

// case law actions
const processCaseParams = (query, filters) => {
  let args = { ...filters };

  if (Object.keys(filters).indexOf('dateRange') >= 0) {
    args.startDate = filters.dateRange[0].format('YYYY-MM-DD');
    args.endDate = filters.dateRange[1].format('YYYY-MM-DD');
    delete args.dateRange;
  }
  if (Object.keys(filters).indexOf('insuranceTypes') >= 0) {
    args.insuranceTypes = _.map(filters.insuranceTypes, 'key').join();
  }
  if (Object.keys(filters).indexOf('providers') >= 0) {
    args.providers = _.map(filters.providers, 'key').join();
  }
  if (Object.keys(filters).indexOf('industry') >= 0) {
    args.providers = filters.industry;
  }

  args = new URLSearchParams({ q: query, ...args });
  return args;
};

export const searchCase = (url, query, filters) => async dispatch => {
  if (url && url.length) {
    const augmented = { ...filters, page: 1, pageSize: 30 };
    dispatch(searchRequest(query, augmented));
    try {
      const params = processCaseParams(query, augmented);
      const resp = await axios({
        method: 'get',
        url,
        params
      });
      dispatch(searchReceived(resp));
    } catch (err) {
      // TODO: send global error message
      // console.error(err);
    }
  }
};

export const flipCase = (url, page, pageSize) => async (dispatch, getState) => {
  const { search } = getState();
  const { query } = search;
  const filters = { ...search.filters, page, pageSize };
  if (url && url.length) {
    dispatch(searchRequest(query, filters));
    try {
      const params = processCaseParams(query, filters);
      const resp = await axios.get(url, { params });
      dispatch(searchReceived(resp));
    } catch (err) {
      // TODO: send global error message
      // console.error(err);
    }
  }
};

export const updateFilter = filters => ({
  type: SEARCH_UPDATE_FILTER,
  filters
});

export const resetFilter = () => ({
  type: SEARCH_RESET_FILTER
});

export const resetSearch = () => ({
  type: SEARCH_RESET_SEARCH
});
