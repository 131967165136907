import React from "react";
import { Icon } from "antd";
import RatingScale from "../components/RatingScale";

import bgFsrWebp from "../assets/home-01.webp";
import bgFsrJpeg from "../assets/home-01.jpg";
import "./portal.scss";

export default class Portal extends React.PureComponent {
  render() {
    return (
      <div className="fr-portal-container">
        <h1 className="page-title">
          <em>Financial</em>Ratings
        </h1>
        <p className="page-intro">
          The ratings shown here are provided with the kind permission of
          Standard &amp; Poor&apos;s.
        </p>
        <main>
          <div className="fr-portal-card">
            <picture>
              <source srcSet={bgFsrWebp} type="image/webp" />
              <source srcSet={bgFsrJpeg} type="image/jpeg" />
              <img src={bgFsrJpeg} alt="financial strength ratings" />
            </picture>
            <article>
              <h2>Financial Strength Ratings</h2>
              <p>
                These ratings are the current opinion of the financial security
                characteristics of an insurance organisation with respect to its
                ability to pay under its insurance policies and contracts in
                accordance with their terms.
              </p>
            </article>
          </div>
          <div className="fr-portal-card">
            <blockquote>
              Credit ratings should generally only be advertised to retail
              clients if the rating is issued by a credit rating agency that is
              authorised under its AFS licence to provide financial advice to
              retail clients.
            </blockquote>
            <div>AuSC regulatory Guideline RG234 at clause 82</div>
          </div>
          <div className="fr-portal-card">
            <div className="fr-portal-card-upper">
              <h2>Understanding Ratings</h2>
              <ul>
                <li>
                  <Icon type="right" />
                  <a
                    target="_blank"
                    href="https://www.standardandpoors.com"
                    rel="noopener noreferrer"
                  >
                    Standard &amp; Poor&apos;s
                  </a>
                </li>
                <li>
                  <Icon type="right" />
                  <a
                    target="_blank"
                    href="http://www.ambest.com"
                    rel="noopener noreferrer"
                  >
                    AM Best
                  </a>
                </li>
                <li>
                  <Icon type="right" />
                  <a
                    target="_blank"
                    href="https://www.fitchratings.com"
                    rel="noopener noreferrer"
                  >
                    Fitch Ratings
                  </a>
                </li>
                <li>
                  <Icon type="right" />
                  <a
                    target="_blank"
                    href="https://www.moodys.com"
                    rel="noopener noreferrer"
                  >
                    Moody&apos;s
                  </a>
                </li>
              </ul>
            </div>
            <RatingScale />
          </div>
        </main>
      </div>
    );
  }
}
