import React from 'react';
import { Row } from 'antd';

import MenuSection from './menuSection/MenuSection';

import mediaIcon from '../assets/mediaIcon.svg';
import financialIcon from '../assets/financialIcon.svg';
import policyIcon from '../assets/policyIcon.svg';
import caseIcon from '../assets/lawIcon.svg';
import insuranceIcon from '../assets/insuranceIcon.svg';

const sectionSnapshot = [
  {
    key: 1,
    sectionIcon: policyIcon,
    sectionTitle: 'Policy Library',
    link: '/policy-library',
    openNew: false
  },
  {
    key: 2,
    sectionIcon: caseIcon,
    sectionTitle: 'Case Law',
    link: '/case-law',
    openNew: false
  },
  {
    key: 3,
    sectionIcon: financialIcon,
    sectionTitle: 'Financial Ratings',
    link: '/financial-ratings',
    openNew: false
  },
  {
    key: 4,
    sectionIcon: mediaIcon,
    sectionTitle: 'News and Articles',
    link: 'https://www.lmigroup.io/news/',
    openNew: true
  },
  {
    key: 5,
    sectionIcon: insuranceIcon,
    sectionTitle: 'Insurance Glossary',
    link: '/insurance-glossary',
    openNew: false,
    public: true
  }
];

export default ({ style }) => (
  <div className="sub-nav-menu" style={style}>
    <Row align="middle" justify="center" type="flex">
      {sectionSnapshot.map(section => (
        <MenuSection menuItem={section} key={section.key} />
      ))}
    </Row>
  </div>
);
