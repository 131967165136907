import React, { PureComponent } from 'react';
import Moment from 'react-moment';

import './caseLawCard.scss';

export default class LawCard extends PureComponent {
  render() {
    const { data } = this.props;
    const { commentary, jurisdiction, title, hearingDate, source } = data;
    return (
      <div className="case-law-card">
          <div className="case-law-card-date">
            <Moment format="DD MMMM YYYY">{hearingDate}</Moment>
          </div>
          <h1>
            <a
              className="case-law-card-title" 
              href={source} 
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
          </h1>
          <h2 className="case-law-card-jurisdiction">{jurisdiction}</h2>
          <p className="case-law-card-content">{commentary}</p>
      </div>
    );
  }
}
