import React, { Component } from 'react';
import ga from 'react-ga';
import { Button, Select, Form } from 'antd';
import { financialCountrySelect as countries } from '../../constants';
import './financialSearch.scss';

const { Option } = Select;

class FinancialSearch extends Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        this.props.clickSearch();
        ga.event({
          category: 'Financial Ratings',
          action: 'Search',
          label: JSON.stringify(values)
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="fr-search-container">
        <Form onSubmit={this.onSubmit} layout="inline">
          <Form.Item colon={false}>
            {getFieldDecorator('country')(
              <Select placeholder="Select a country" size="large">
                {countries.map(country => (
                  <Option key={country.code} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item colon={false}>
            <Button type="primary" className="fr-search-btn" htmlType="submit" size="large">
              Look up
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const WrappedFinancialSearch = Form.create({
  onValuesChange(props, changedValues) {
    props.handleFormChange(changedValues);
  },
  mapPropsToFields(props) {
    const { country } = props;
    return {
      country: Form.createFormField({
        value: country
      })
    };
  }
})(FinancialSearch);

export default WrappedFinancialSearch;
