import { types } from './appAction';
import { types as loginTypes } from '../login/loginAction';

const initialState = { initialized: false, requestFeedback: '', loadingUser: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APP_INITIALIZING:
      return { ...state, initialized: false };
    case types.APP_INITIALIZED:
      return { ...state, initialized: true };
    case loginTypes.USER_LOGOUT:
      return { ...initialState };
    case types.REQUEST_FEEDBACK:
      return { ...state, requestFeedback: action.requestFeedback };
    case types.REQUEST_USER:
      return { ...state, loadingUser: true };
    case types.RECEIVE_USER:
      return { ...state, loadingUser: false, user: action.user };
    default:
      return state;
  }
};
