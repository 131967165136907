import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ga from 'react-ga';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { fetchInsuranceType, setInsuranceTypes } from '../../scenes/policyLibrary/PolicyAction';
import { updateQuery } from './action';
import { userCountrySelector } from '../app/appSelectors';
import './search.scss';

const { Search } = Input;

const AdvancedSearch = ({
  filters,
  placeholder,
  children,
  defaultFilters,
  category,
  search,
  url,
  userCountry,
  resetSearch,
  resetFilter,
  setInsuranceTypes,
  fetchInsuranceType,
  updateFilter,
  updateQuery
}) => {
  const [expanded, setExpanded] = useState(true);
  const [query, setQuery] = useState('');

  useEffect(() => {
    resetSearch();
  }, [resetSearch]);

  const toggleFilter = () => {
    setExpanded(!expanded);
  };

  const reset = () => {
    resetFilter();
    setInsuranceTypes([]);
    fetchInsuranceType(userCountry);
    updateFilter({ country: userCountry });
  };

  const onSearch = (query, action) => {
    const params = { ...defaultFilters, ...filters, country: filters.country || defaultFilters.country };
    search(url, query, params);
    setQuery(query);
    setExpanded(false);
    ga.event({
      category,
      action,
      label: JSON.stringify(params)
    });
  };

  const updateSearchQuery = e => {
    setQuery(e.target.value);
    updateQuery(e.target.value);
  };

  return (
    <div className="search-container">
      <div className="search-query-container">
        <Search
          className="search-input-query"
          placeholder={placeholder}
          size="large"
          enterButton
          onChange={updateSearchQuery}
          onSearch={value => onSearch(value, 'Search')}
        />
        <Button className="search-button-filter" size="large" onClick={toggleFilter}>
          <i className="fas fa-sliders-h" />
        </Button>
      </div>
      <CSSTransition in={expanded} timeout={150} classNames="search-filter-container" unmountOnExit>
        <div className="search-filter-container">
          {React.cloneElement(children, {
            filters,
            onChange: updateFilter
          })}
          <div className="search-filter-actions">
            <Button icon="close" onClick={reset}>
              Reset
            </Button>
            <Button type="primary" icon="check" onClick={() => onSearch(query, 'Filter')}>
              Apply Filter
            </Button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

Search.defaultProps = {
  defaultFilters: {},
  placeholder: 'Search'
};

Search.propTypes = {
  filters: PropTypes.object.isRequired,

  /**
   * The search API endpoint
   */
  url: PropTypes.string.isRequired,

  /**
   * Event category for Google Analytics
   */
  category: PropTypes.string.isRequired,

  /**
   * Default filter values
   */
  defaultFilters: PropTypes.object,

  /**
   * Placeholder text for search box
   */
  placeholder: PropTypes.string
};

const mapStateToProps = state => ({ userCountry: userCountrySelector(state), ...state.search });

const mapDispatchToProps = {
  fetchInsuranceType,
  setInsuranceTypes,
  updateQuery
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);
