import React, { useState } from 'react';
import { Modal } from 'antd';

export default ({ children, toggle, defaultVisible, ...props }) => {
  const [visible, setVisible] = useState(defaultVisible || false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <>
      {toggle(show)}
      <Modal visible={visible} footer={null} keyboard={false} destroyOnClose onCancel={hide} {...props}>
        {children(hide)}
      </Modal>
    </>
  );
};
